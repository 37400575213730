import { DateTime } from "luxon";

export function isExpiredJWT(exp) {
  return Date.now() - exp * 1000 >= 0;
}

export function formatName(name) {
  const [firstName, lastName] = name.split(".");
  return (
    firstName[0].toUpperCase() +
    firstName.substring(1) +
    " " +
    lastName[0].toUpperCase() +
    lastName.substring(1)
  );
}

export function revertName(formattedName) {
  return formattedName.split(" ").join(".").toLowerCase();
}

export function formatDate(date) {
  return DateTime.fromISO(date)
    .setZone("Europe/Bucharest")
    .setLocale("ro")
    .toLocaleString(DateTime.DATETIME_SHORT);
}

export const TRUCKS_L = [
  "B 101 TRL",
  "B 222 TRL",
  "B 333 TRL",
  "B 350 TRL",
  "B 444 TRL",
  "B 450 TRL",
  "B 550 TRL",
  "B 555 TRL",
  "B 650 TRL",
  "B 777 TRL",
  "B 999 TRL",
];

export const TRAILERS_L = [
  "B 130 TRL",
  "B 140 TRL",
  "B 230 TRL",
  "B 240 TRL",
  "B 260 TRL",
  "B 270 TRL",
  "B 280 TRL",
  "B 330 TRL",
  "B 340 TRL",
  "B 430 TRL",
  "B 530 TRL",
  "B 540 TRL",
  "B 630 TRL",
];

export const TRUCKS_B = ["B 150 TRL", "B 850 TRL"];

export const TRAILERS_B = ["B 160 TRL", "B 120 TRL"];

export const INCARCARE_B = [
  "AGREWEST",
  "SALCIOARA",
  "ROM AGREGATE-BOLINTIN",
  "BAUMIT",
  "FLORESTI",
];

export const LIVRARE_B = ["BAUMIT-BOLINTIN"];

// export const API_URL = "https://safirtrans-transport-api.onrender.com";
export const API_URL =
  "https://safirtrans-transport-api-61fbb6304b19.herokuapp.com";
// export const API_URL = "http://192.168.0.179:8080";

export function rutaFilter({ options, search }) {
  return options.filter((option) => {
    return option.value.startsWith(search);
  });
}

export function downloadCSV(data) {
  if (!data.length) {
    return "";
  }

  const excludeKeys = ["_id"];

  data.forEach((obj) => (obj.date = formatDate(obj.date)));

  const translateMap = {
    date: "Data",
    truck: "Camion",
    trailer: "Remorca",
    loc_incarcare: "Loc incarcare",
    loc_livrare: "Loc livrare",
    aviz_interbulk: "Aviz Interbulk",
    aviz_safirtrans: "Aviz Safirtrans",
    cantitate: "Cantitate",
    sort: "Sort",
  };

  // Extract the headers (keys of the first object)
  const headers = Object.keys(data[0]).filter(
    (key) => !excludeKeys.includes(key)
  );

  const translatedHeaders = headers.map((header) => translateMap[header]);

  // Map the headers and data rows to CSV format
  const rows = data.map((obj) =>
    headers
      .map(
        (header) =>
          // Escape quotes and commas in values
          `"${String(obj[header] || "").replace(/"/g, '""')}"`
      )
      .join(",")
  );

  // Combine headers and rows
  return [translatedHeaders.join(","), ...rows].join("\n");
}

export function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}
