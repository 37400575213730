import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import {
  API_URL,
  formatDate,
  formatName,
  isExpiredJWT,
  revertName,
} from "../utils";
import { Button, Select, TextInput } from "@mantine/core";
import { useLocation } from "react-router-dom";
import useFeedback from "../hooks/useFeedback";
import CustomError from "./CustomError";
import "../css/spinner.css";

let cfgUsers = [];

function ETransport() {
  const { auth, refresh } = useContext(AuthContext);
  const isAdmin = auth.user.permissions.includes("Admin");
  const [users, setUsers] = useState(null);
  const [err, setErr] = useState(null);
  const [uit, setUit] = useState("");
  const [transports, setTransports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setFeedback = useFeedback();
  let location = useLocation();

  const path = location.pathname.match(/\/l\/.*/i) ? "l" : "b";

  const errorCfg = {
    added_by_user: {
      func: (v) => cfgUsers.find((user) => user.name === revertName(v)),
      msg: "Nume utilizator invalid",
    },
    uit: {
      func: (v) => v.length > 0,
      msg: "Introduceti cod UIT",
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: "",
      added_by_user: "",
      uit: "",
    },
  });

  // GET USERS
  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get(
          API_URL + `/api/user/?active=true&normal=${path}`,
          {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }
        );
        cfgUsers = res.data;
        setUsers(res.data);
      } catch (err) {
        console.error(err.response);
        setErr(err);
      }
    }

    if (isAdmin) {
      if (isExpiredJWT(auth.user.exp)) refresh();
      else getUsers();
    } else setUsers(auth.user);
  }, [auth, refresh, isAdmin, path]);

  useEffect(() => {
    async function getRunningTransports() {
      try {
        const res = await axios.get(
          API_URL +
            `/api/etransport${
              isAdmin ? "/admin" : `/?added_by_user=${auth.user.name}`
            }`,
          {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }
        );
        setTransports(res.data);
      } catch (err) {
        console.error(err.response);
        setErr(err);
      }
    }

    getRunningTransports();
  }, [auth.aT, isAdmin, auth.user.name]);

  async function onSubmit(data) {
    data.date = new Date().toISOString();
    const user = isAdmin
      ? users.find((user) => user.name === revertName(data.added_by_user))
      : auth.user;
    data.added_by_user = user._id;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      setIsLoading(true);
      const res = await axios.post(API_URL + `/api/etransport/start`, data, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setFeedback({
        type: "Success",
        active: true,
        msg: "Transportul este acum monitorizat.",
      });
      setTransports([...transports, res.data.transport]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Transportul nu a putut fi monitorizat.",
      });
    }
  }

  async function handleStop(transport) {
    if (
      window.confirm(`Doriti sa finalizati transportul UIT:${transport.uit}?`)
    ) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      setIsLoading(true);
      try {
        await axios.post(
          API_URL + `/api/etransport/${transport.uit}/stop`,
          transport,
          { headers: { Authorization: `Bearer ${aT}` } }
        );
        setFeedback({
          type: "Success",
          active: true,
          msg: "Transportul a fost finalizat cu succes.",
        });
        setTransports((prevTransports) =>
          prevTransports.filter(
            (_transport) => _transport.uit !== transport.uit
          )
        );
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Transportul nu a putut fi finalizat.",
        });
      }
    }
  }

  async function updateUIT(e) {
    setUit(e.target.value.toUpperCase());
    setValue("uit", e.target.value.toUpperCase());
  }

  function formatNames() {
    return users.map((user) => formatName(user.name));
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <h1 className="text-2xl font-bold mb-4">Creeaza transport</h1>
      {users && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-[400px] flex flex-col gap-2"
        >
          {isAdmin && (
            <>
              <Controller
                name="added_by_user"
                control={control}
                rules={{
                  validate: (v) =>
                    errorCfg.added_by_user.func(v) ||
                    errorCfg.added_by_user.msg,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Sofer"
                    withAsterisk
                    placeholder="Selecteaza un sofer..."
                    nothingFoundMessage="Sofer inexistent"
                    searchable
                    data={formatNames()}
                  />
                )}
              />
            </>
          )}

          <Controller
            name="uit"
            control={control}
            rules={{
              validate: (v) => errorCfg.uit.func(v) || errorCfg.uit.msg,
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                label="UIT"
                withAsterisk
                placeholder="Completeaza UIT..."
                onChange={(e) => {
                  updateUIT(e);
                  field.onChange(e);
                }}
                value={uit}
                error={errors.uit ? errors.uit.message : ""}
              />
            )}
          />
          {isLoading && (
            <div className="spinner-container w-[75px] !h-[75px]">
              <div className="spinner"></div>
            </div>
          )}

          <Button
            type="submit"
            className="!w-[100px] mt-2"
            onClick={handleSubmit(onSubmit)}
          >
            Trimite
          </Button>
        </form>
      )}
      {transports.length > 0 && (
        <>
          <h2 className="text-2xl font-bold mb-4 mt-4">
            Transporturi in desfasurare
          </h2>
          <MobileTable transports={transports} handleStop={handleStop} />
        </>
      )}
    </div>
  );
}

function MobileTable({ transports, handleStop }) {
  if (!transports.length) return;
  return (
    <div>
      <div className="divide-y divide-black/50 max-w-[500px]">
        {transports.map((transport) => (
          <Item
            key={transport.uit}
            transport={transport}
            handleStop={handleStop}
          />
        ))}
      </div>
    </div>
  );
}

function Item({ transport, handleStop }) {
  const [elapsed, setElapsed] = useState(getElapsedTime(transport.date));

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(getElapsedTime(transport.date));
    }, 1000);

    return () => clearInterval(interval);
  }, [transport.date]);

  return (
    <div
      key={transport.uit}
      className="flex items-center justify-between py-4 hover:bg-black/10 px-2  transition-all"
    >
      <div className="flex flex-col">
        <span className="font-medium">UIT: {transport.uit}</span>
        <span className="text-xs">Inceput: {formatDate(transport.date)}</span>
        <span className="text-s">Durata: {formatElapsedTime(elapsed)}</span>
      </div>
      <Button
        type="submit"
        className="!w-[110px]"
        onClick={() => handleStop(transport)}
      >
        Finalizare
      </Button>
    </div>
  );
}

const getElapsedTime = (isoDate) => {
  const startDate = new Date(isoDate);
  const now = new Date();
  return Math.floor((now - startDate) / 1000); // elapsed seconds
};

const formatElapsedTime = (seconds) => {
  const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
  const secs = String(seconds % 60).padStart(2, "0");
  return `${hours}:${minutes}:${secs}`;
};

export default ETransport;
