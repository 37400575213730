import { NavbarLink } from "./NavbarLink";

function AdminNavbar({ toggle, opened }) {
  return (
    <div className="flex flex-col font-bold relative gap-3">
      <NavbarLink link="/" toggle={toggle}>
        Acasa
      </NavbarLink>
      <NavbarLink link="/l" toggle={toggle}>
        Lidl
      </NavbarLink>
      <NavbarLink link="/l/create" toggle={toggle} ml={4}>
        Creeaza transport
      </NavbarLink>
      <NavbarLink link="/l/list" toggle={toggle} ml={4}>
        Lista transporturi
      </NavbarLink>
      <NavbarLink link="/l/calculator" toggle={toggle} ml={4}>
        Calculator
      </NavbarLink>
      <NavbarLink link="/l/rute" toggle={toggle} ml={4}>
        Rute
      </NavbarLink>
      <NavbarLink link="/l/users" toggle={toggle} ml={4}>
        Utilizatori
      </NavbarLink>
      <NavbarLink link="/l/eTransport" toggle={toggle} ml={4}>
        eTransport
      </NavbarLink>
      <NavbarLink link="/b" toggle={toggle}>
        Basculante
      </NavbarLink>
      <NavbarLink link="/b/create" toggle={toggle} ml={4}>
        Creeaza transport
      </NavbarLink>
      <NavbarLink link="/b/list" toggle={toggle} ml={4}>
        Lista transporturi
      </NavbarLink>
      <NavbarLink link="/b/calculator" toggle={toggle} ml={4}>
        Calculator / CSV
      </NavbarLink>
      <NavbarLink link="/b/eTransport" toggle={toggle} ml={4}>
        eTransport
      </NavbarLink>
    </div>
  );
}

export default AdminNavbar;
